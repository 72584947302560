
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.feasts {
  &__actions {
    display: flex;
    flex-direction: row;
    &-item {
      cursor: pointer;
      margin: 0 0 0 10px;
      &:first-child {
        margin: 0;
      }
    }
  }
}

.form-item {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}

.services {
  &__search {
    max-width: 400px;
  }
}

.services, .feasts {
  .esmp-table__thead-tr {
    .esmp-table__thead-th:last-child {
      width: 48px;
    }
  }
}

.feasts {
  .esmp-table__thead-tr {
    .esmp-table__thead-th:first-child {
      width: 42px;
    }
    .esmp-table__thead-th:nth-child(4) {
      width: 48px;
    }
  }
}
